import { wsChatUrl } from "@/requestUrl";
import vc from "../../../common/core";


/**
 * 更新单条柱状图数据
 * @param {Object} chart 图表实例
 * @param {Number} index 要修改的列的下标
 * @param {Number | String} data 具体某一项修改值
 * */
function updateSingleBarChartCount(chart, index, data) {
    const viewCount = chart.getOption().series[0].data;
    viewCount[index] = data;
    chart.setOption({
        series: [
            { data: viewCount }
        ]
    })
}

/**
 * 初始化socket连接
 * @param {Function} messageHandler 处理接受到后台消息
 * @param {String} url 请求地址
 * */
function initWs({ messageHandler, url = `${wsChatUrl}?regionCode=${vc.getCurrentRegion().code}${vc.getCurrentStaffInfo().id}${new Date().getTime()}` }) {
    let socketHeartbeatTimer;
    const socket = new WebSocket(url);
    socket.addEventListener('open', function(event) {
        socket.send('连接开始', event);
        socketHeartbeatTimer = setInterval(() => {
            socket.send('心跳');
        }, 15000);
    });
    socket.addEventListener('message', event => {
        messageHandler(event)
    });
    socket.addEventListener('close', event => {
        clearInterval(socketHeartbeatTimer);
        console.log('关闭连接', event);
    })
    socket.addEventListener('error', event => {
        console.log('连接错误', event);
    })
    return socket;
}

/**
 * 创建评价百分比柱状图所需数据
 * @param {Object} 原数据
 * @param {Object} 存在的类别
 * */
function createEvaluateBarSeries(originData, scoreIndex) {
    const legendNames = ['未评价', '一星', '二星', '三星', '四星', '五星'];
    let series = [],
        noScore = [],
        oneScore = [],
        twoScore = [],
        threeScore = [],
        fourScore = [],
        fiveScore = [];

    Object.entries(originData).forEach(([key, data]) => {
        noScore[scoreIndex[key]] = +data.noScore;
        oneScore[scoreIndex[key]] = +data.oneScore;
        twoScore[scoreIndex[key]] = +data.twoScore;
        threeScore[scoreIndex[key]] = +data.threeScore;
        fourScore[scoreIndex[key]] = +data.fourScore;
        fiveScore[scoreIndex[key]] = +data.fiveScore;
    })
    const legendData = {
        '未评价': noScore,
        '一星': oneScore,
        '二星': twoScore,
        '三星': threeScore,
        '四星': fourScore,
        '五星': fiveScore,
    };
    legendNames.forEach(legend => {
        series.push({
            name: legend,
            type: 'bar',
            stack: '评价',
            label: {
                show: true,
                textStyle: { color: '#fff' },
                formatter: e => e.data > 0 ? e.data + '%' : '',
            },
            emphasis: {
                focus: 'series'
            },
            data: legendData[legend]
        });
    })
    return series;
}

export {
    initWs,
    createEvaluateBarSeries,
    updateSingleBarChartCount,
}