<template>
  <div :class="['border-line', direction]" ref="borderLine">
  </div>
</template>

<script>
export default {
  name: "DataItemPanelLeftBorder",
  props: {
    direction: {
      type: String,
      default: 'vertical' // vertical， horizontal
    }
  },
  data() {
    return {

    }
  },
  mounted() {
    const currentRef = this.$refs.borderLine;
    let nextSibling = currentRef.nextSibling.clientHeight;
    if (this.direction === 'horizontal') {
      nextSibling = currentRef.nextSibling.clientWidth;
      currentRef.style.width = currentRef.nextSibling.clientWidth + 'px';
    } else {
      currentRef.style.height = currentRef.nextSibling.clientHeight + 'px';
    }
    let squareItems = '',
        squareItemsCount = Math.floor((nextSibling - 16 - 5) / 13);
    for(let i = 0; i < squareItemsCount; i++) {
      squareItems += '<div class="square-item"></div>';
    }
    if ((nextSibling - 16 - 5) % 13 > 8) {
      squareItems += `<div className="square-item" style="height: ${(nextSibling - 16 - 5) % 13 - 5}px;"></div>`;
    }
    currentRef.innerHTML = `
      <div class="triangle-item"></div>
      ${squareItems}
      <div class="triangle-item reverse"></div>
    `;
    let lightIndex = Math.floor(squareItemsCount / 2);
    currentRef.children.forEach((el, index) => {
      if (index - 2 < lightIndex && el.className.includes('square-item')) {
        el.className = 'square-item light';
      }
    })
    /*setInterval(() => {
      lightIndex++;
      if (lightIndex >= currentRef.children.length - 2) {
        lightIndex = Math.floor((currentRef.children.length - 2) / 2);
        const squareList = currentRef.getElementsByClassName('square-item');
        for(let i = lightIndex; i < squareList.length; i++) {
          try {
            squareList[i].classList.remove('light');
          } catch (err) {
            console.log('内部报错');
          }
        }
      }
      try {
        currentRef.getElementsByClassName('square-item')[lightIndex].classList.add('light');
      } catch (err) {
        console.log('外部报错', lightIndex);
      }
    }, 200)*/
  }
}
</script>

<style lang="stylus">
.border-line
  //height var(--border-line-height, 100px)
  display inline-flex
  vertical-align middle
  flex-flow column nowrap
  align-items center
  justify-content space-between
  margin-right 1px
  &.vertical
    width 8px
  &.horizontal
    height 8px
    flex-direction row
    .triangle-item
      //margin-right 5px
      //margin-bottom 0
      transform rotateZ(-90deg)
      &.reverse
        transform rotateZ(90deg)
    .square-item
      //margin-right 5px
      //margin-bottom 0
  .triangle-item
    position relative
    width 10px
    height 8px
    background rgba(26, 201, 255, 1)
    //margin-bottom 5px
    overflow hidden
    background url("../../assets/bigData/1/sj.png")
    background-size 100% 100%
    &.reverse
      transform rotateZ(180deg)
    /*&::before
      position absolute
      content " "
      right -6px
      bottom 2px
      width 9px
      height 12px
      background #000839
      transform rotate(45deg)
    &::after
      position absolute
      content " "
      left -6px
      bottom 2px
      width 9px
      height 12px
      background #000839
      transform rotate(-45deg)*/
  .square-item
    width 8px
    height 8px
    background #0D2E61
    //margin-bottom 5px
    &.light
      background rgba(26, 201, 255, 1)
</style>